<template>
    <div class="object-info"
        v-html="getObjectCount"></div>
</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';
    import doReq from 'voUtils/RestEx.js';

    export default {
    	voVueComponent: 'facility-object-info',
    	props: {
    		facilityid: String
    	},

    	computed: {
    		getObjectCount: function() {

    			/*var req = {
    				filter: {
    					facility: parseInt(this.facilityid, 10)
    				},
    				sorting: 'random',
    			};

    			doReq('searchUnits', req)
    				.then((res) => {
    					console.log('this.units::' + res.units);
    				});*/


    			if (this.facilityid == '139902') {
    				return '<span>46 Objekte</span>';
    			} else if (this.facilityid == '139914') {
    				return '<span>45 Objekte</span>';
    			} else if (this.facilityid == '139891') {
    				return '<span>45 Objekte</span>';
    			} else if (this.facilityid == '139836') {
    				return '<span>16 Objekte</span>';
    			} else if (this.facilityid == '139917') {
    				return '<span>48 Objekte</span>';
    			} else if (this.facilityid == '139847') {
    				return '<span>22 Objekte</span>';
    			} else if (this.facilityid == '139911') {
    				return '<span>49 Objekte</span>';
    			} else if (this.facilityid == '139858') {
    				return '<span>1 Objekt</span>';
    			} else if (this.facilityid == '139910') {
    				return '<span>101 Objekte</span>';
    			} else if (this.facilityid == '139869') {
    				return '<span>1 Objekt</span>';
    			} else if (this.facilityid == '139859') {
    				return '<span>2 Objekte</span>';
    			} else if (this.facilityid == '139884') {
    				return '<span>25 Objekte</span>';
    			} else if (this.facilityid == '139880') {
    				return '<span>35 Objekte</span>';
    			} else if (this.facilityid == '139921') {
    				return '<span>35 Objekte</span>';
    			} else if (this.facilityid == '139846') {
    				return '<span>1 Objekt</span>';
    			} else if (this.facilityid == '139875') {
    				return '<span>30 Objekte</span>';
    			} else if (this.facilityid == '147397') {
    				return '<span>1 Objekt</span>';
    			} else if (this.facilityid == '139916') {
    				return '<span>53 Objekte</span>';
    			} else if (this.facilityid == '139885') {
    				return '<span>1 Objekt</span>';
    			} else if (this.facilityid == '139888') {
    				return '<span>1 Objekt</span>';
    			} else if (this.facilityid == '139893') {
    				return '<span>1 Objekt</span>';
    			} else if (this.facilityid == '139876') {
    				return '<span>2 Objekte</span>';
    			} else if (this.facilityid == '139900') {
    				return '<span>1 Objekt</span>';
    			} else if (this.facilityid == '139899') {
    				return '<span>1 Objekt</span>';
    			} else if (this.facilityid == '139894') {
    				return '<span>3 Objekte</span>';
    			} else if (this.facilityid == '139878') {
    				return '<span>1 Objekt</span>';
    			} else if (this.facilityid == '139881') {
    				return '<span>1 Objekt</span>';
    			} else if (this.facilityid == '139919') {
    				return '<span>1 Objekt</span>';
    			} else if (this.facilityid == '139866') {
    				return '<span>8 Objekte</span>';
    			} else if (this.facilityid == '139871') {
    				return '<span>16 Objekte</span>';
    			} else if (this.facilityid == '139872') {
    				return '<span>10 Objekte</span>';
    			} else if (this.facilityid == '139873') {
    				return '<span>7 Objekte</span>';
    			} else if (this.facilityid == '139853') {
    				return '<span>8 Objekte</span>';
    			} else if (this.facilityid == '139879') {
    				return '<span>2 Objekte</span>';
    			} else if (this.facilityid == '139906') {
    				return '<span>30 Objekte</span>';
    			} else if (this.facilityid == '139915') {
    				return '<span>3 Objekte</span>';
    			} else if (this.facilityid == '139874') {
    				return '<span>28 Objekte</span>';
    			} else if (this.facilityid == '139867') {
    				return '<span>1 Objekt</span>';
    			} else if (this.facilityid == '139912') {
    				return '<span>2 Objekte</span>';
    			} else if (this.facilityid == '139860') {
    				return '<span>7 Objekte</span>';
    			} else if (this.facilityid == '139837') {
    				return '<span>1 Objekt</span>';
    			} else if (this.facilityid == '139838') {
    				return '<span>2 Objekt</span>';
    			} else if (this.facilityid == '139907') {
    				return '<span>1 Objekt</span>';
    			} else if (this.facilityid == '139849') {
    				return '<span>1 Objekt</span>';
    			} else if (this.facilityid == '139918') {
    				return '<span>1 Objekt</span>';
    			} else if (this.facilityid == '139883') {
    				return '<span>35 Objekte</span>';
    			} else if (this.facilityid == '139896') {
    				return '<span>1 Objekt</span>';
    			} else if (this.facilityid == '139839') {
    				return '<span>3 Objekte</span>';
    			} else if (this.facilityid == '139854') {
    				return '<span>28 Objekte</span>';
    			} else if (this.facilityid == '139855') {
    				return '<span>38 Objekte</span>';
    			} else if (this.facilityid == '139870') {
    				return '<span>3 Objekte</span>';
    			} else if (this.facilityid == '139844') {
    				return '<span>16 Objekte</span>';
    			} else if (this.facilityid == '139898') {
    				return '<span>2 Objekte</span>';
    			} else if (this.facilityid == '139886') {
    				return '<span>2 Objekte</span>';
    			} else if (this.facilityid == '139887') {
    				return '<span>6 Objekte</span>';
    			} else if (this.facilityid == '139850') {
    				return '<span>4 Objekte</span>';
    			} else if (this.facilityid == '139840') {
    				return '<span>2 Objekte</span>';
    			} else if (this.facilityid == '139851') {
    				return '<span>10 Objekte</span>';
    			} else if (this.facilityid == '139841') {
    				return '<span>18 Objekte</span>';
    			} else if (this.facilityid == '139856') {
    				return '<span>11 Objekte</span>';
    			} else if (this.facilityid == '139857') {
    				return '<span>8 Objekte</span>';
    			} else if (this.facilityid == '139920') {
    				return '<span>1 Objekt</span>';
    			} else if (this.facilityid == '139889') {
    				return '<span>1 Objekt</span>';
    			} else if (this.facilityid == '139890') {
    				return '<span>2 Objekte</span>';
    			} else if (this.facilityid == '139842') {
    				return '<span>2 Objekte</span>';
    			} else if (this.facilityid == '139895') {
    				return '<span>1 Objekt</span>';
    			} else if (this.facilityid == '160536') {
    				return '<span>41 Objekte</span>';
    			} else if (this.facilityid == '171532') {
    				return '<span>1 Objekt</span>';
    			} else {
    				return '<span class="hidden"></span>';
    			}




    			/*else if (this.regionname === 'Borkum' || this.regionname === 'Bensersiel' || this.regionname === 'Langeoog' || this.regionname === 'Schillig' || this.regionname === 'Wangerooge' || this.regionname === 'Wremen') {
    			    				return 'Nordsee';
    			    			} else if (this.regionname === 'Travemünde' || this.regionname === 'Großenbrode' || this.regionname === 'Boltenhagen' || this.regionname === 'Wismar' || this.regionname === 'Börgerende' || this.regionname === 'Graal-Müritz' || this.regionname === 'Rügen' || this.regionname === 'Usedom') {
    			    				return 'Ostsee';
    			    			} 
    			else {
    				return 'Pending';
    			}*/
    		}
    	}
    };
</script>