<script>
    import V from 'voUtils/V.js'



    export default {
    	voVueComponent: 'facility-img-carousel',
    	props: {
    		carouselid: String
    	},

    	mounted: function() {

    		$("#" + this.carouselid).owlCarousel({
    			items: 1,
    			loop: true,
    			center: false,
    			autoplay: false,
    			smartSpeed: 1000,
    			dots: false,
    			nav: true
    		});
    	}
    };
</script>