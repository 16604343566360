<template>
    <a href="javascript:0;"
        data-toggle="popover"
        :title="getTitleText"
        data-trigger="hover"
        data-placement="bottom"
        data-html="true"
        :data-content="getPriceText">
        <i class="fal fa-info-circle"></i>
    </a>

</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'price-list-help',
    	props: {
    		servicename: String
    	},
    	mounted: function() {
    		$('[data-toggle="popover"]').popover();
    	},
    	computed: {
    		getTitleText: function() {
    			return this.servicename;
    		},
    		getPriceText: function() {
    			//console.log('pricelist::' + this.servicename);
    			var priceText = '';
    			if (this.servicename == 'Haustier') {
    				priceText = 'Bei mehr als einem Haustier kontaktieren Sie bitte unser Urlaubstelefon unter 04921-8997-3203';
    			} else if (this.servicename == 'Kinderbett') {
    				priceText = 'Kinderbett mit Bettwäsche und Handtücher';
    			} else if (this.servicename == 'Kleinkindausstattung') {
    				priceText = 'Die Kleinkindausstattung besteht aus: Babykost- und Flaschenwärmer, Babyphone, Wickelauflage, Windeleimer und Trittschemel';
    			} else if (this.servicename == 'Wäschepaket') {
    				priceText = 'Das Wäschepaket besteht aus einem Handtuch, einem Badetuch, einem Duschvorleger (pro Bad) sowie Bettwäsche';
    			} else if (this.servicename == 'Übernachtungssteuer') {
    				priceText = 'Die Hansestadt Wismar erhebt eine Übernachtungssteuer bei Privatreisen in Höhe von 5 % auf den Bruttoübernachtungspreis. Die Übernachtungssteuer wird in der Buchungsbestätigung ausgewiesen.';
    			}

    			return priceText;
    		}
    	}
    };
</script>