export default {
	data: {
		selectedImageIndex: undefined,
		showAnmelden: true
	},
	beforeMount: function () {

		/*$(".139891").each(function () {
			$(this).href = './aparthotel-anna-duene-wangerooge';
			$(this).prop('href', './aparthotel-anna-duene-wangerooge');
		});
		$(".139902").each(function () {
			$(this).prop('href', './aparthotel-waterkant-suites');
			$(this).href = './aparthotel-waterkant-suites';
		});
		$(".139914").each(function () {
			$(this).prop('href', './aparthotel-ostseeallee-boltenhagen');
			$(this).href = './aparthotel-ostseeallee-boltenhagen';
		});*/

	},
	mounted: function () {
		$(".139891").each(function () {
			$(this).href = './aparthotel-anna-duene-wangerooge';
			$(this).prop('href', './aparthotel-anna-duene-wangerooge');
		});
		$(".139902").each(function () {
			$(this).prop('href', './aparthotel-waterkant-suites');
			$(this).href = './aparthotel-waterkant-suites';
		});
		$(".139914").each(function () {
			$(this).prop('href', './aparthotel-ostseeallee-boltenhagen');
			$(this).href = './aparthotel-ostseeallee-boltenhagen';
		});
		$(".139916").each(function () {
			$(this).prop('href', './ostseeheilbad-travemuende');
			$(this).href = './aparthotel-ostseeheilbad-travemuende';
		});
		$(".139917").each(function () {
			$(this).prop('href', './ostseebad-grossenbrode');
			$(this).href = './aparthotel-ostseebad-grossenbrode';
		});
		$(".139921").each(function () {
			$(this).prop('href', './hansestadt-wismar');
			$(this).href = './hansestadt-wismar';
		});
		$(".160536").each(function () {
			$(this).prop('href', './krusespeicher');
			$(this).href = './krusespeicher';
		});

	},
	/*updated: function () {
		console.log('updated mount');

	} */

};