<template>
    <h5 v-html="getAddressDetail"></h5>
</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'facility-info',
    	props: {
    		facilityid: String
    	},

    	computed: {
    		getAddressDetail: function() {

    			if (this.facilityid == '139836') {
    				return 'Hermann-Häcker-Straße 42a <span class="line2">18225 Ostseebad Kühlungsborn </span>';
    			} else if (this.facilityid == '139917') {
    				return 'Südstrand 54 <span class="line2">23775 Ostseeheilbad Großenbrode </span>';
    			} else if (this.facilityid == '139847') {
    				return 'Zur Seebrücke 2 <span class="line2">18225 Ostseebad Kühlungsborn </span>';
    			} else if (this.facilityid == '139911') {
    				return 'Barkhausenstraße 23 <span class="line2">26465 Nordseeinsel Langeoog </span>';
    			} else if (this.facilityid == '139858') {
    				return 'Hermannstraße 27a <span class="line2">18225 Ostseebad Kühlungsborn </span>';
    			} else if (this.facilityid == '139910') {
    				return 'Taddigshörn 206-215 <span class="line2">26427 Nordseeheilbad Bensersiel </span>';
    			} else if (this.facilityid == '139869') {
    				return 'Waldstraße 47a <span class="line2">18225 Ostseebad Kühlungsborn </span>';
    			} else if (this.facilityid == '139859') {
    				return 'Kirschenweg 10 und 31 <span class="line2">18551 Glowe</span>';
    			} else if (this.facilityid == '139884') {
    				return 'Dünenstraße 30 b-g <span class="line2">18609 Ostseebad Binz</span>';
    			} else if (this.facilityid == '139880') {
    				return 'Ostseeallee 36-37 <span class="line2">18225 Ostseebad Kühlungsborn </span>';
    			} else if (this.facilityid == '139921') {
    				return 'Stockholmer Straße 16 <span class="line2">23966 Hansestadt Wismar </span>';
    			} else if (this.facilityid == '139846') {
    				return 'Kastanienallee 24 / Haus 12 <span class="line2">23946 Ostseebad Boltenhagen </span>';
    			} else if (this.facilityid == '139875') {
    				return 'Peterstraße 9-9a / Elisabeth-Anna-Straße 10  <span class="line2">26486 Nordseeheilbad Wangerooge </span>';
    			} else if (this.facilityid == '147397') {
    				return 'Ostseeallee 17 <span class="line2">23946 Ostseebad Boltenhagen </span>';
    			} else if (this.facilityid == '139916') {
    				return 'Godewind 5 <span class="line2">23570 Ostseeküste Travemünde </span>';
    			} else if (this.facilityid == '139885') {
    				return 'Kiebitzdelle-Achterlangs 1 <span class="line2">26757 Nordseeheilbad Borkum </span>';
    			} else if (this.facilityid == '139888') {
    				return 'Unter den Kolonaden 3 <span class="line2">18225 Ostseebad Kühlungsborn </span>';
    			} else if (this.facilityid == '139893') {
    				return 'Mellumweg 2 <span class="line2">26434 Nordseeheilbad Schillig </span>';
    			} else if (this.facilityid == '139876') {
    				return 'Anton-Günther-Straße 4 <span class="line2">26486 Nordseeheilbad Wangerooge </span>';
    			} else if (this.facilityid == '139900') {
    				return 'Kiebitzweg 2 <span class="line2">18181 Seeheilbad Graal-Müritz </span>';
    			} else if (this.facilityid == '139899') {
    				return 'Friedrich-Borgwardt-Straße 29 <span class="line2">18225 Ostseebad Kühlungsborn </span>';
    			} else if (this.facilityid == '139894') {
    				return 'Eikkaterweg 8 <span class="line2">18181 Seeheilbad Graal-Müritz </span>';
    			} else if (this.facilityid == '139878') {
    				return 'Obere Strandpromenade 37 <span class="line2">26486 Nordseeheilbad Wangerooge </span>';
    			} else if (this.facilityid == '139881') {
    				return 'Westingstraße 3 <span class="line2">26486 Nordseeheilbad Wangerooge </span>';
    			} else if (this.facilityid == '139919') {
    				return 'Südstrand 308 <span class="line2">18609 Ostseebad Binz OT Prora</span>';
    			} else if (this.facilityid == '139866') {
    				return 'Richthofenstraße 14-18 <span class="line2">26757 Nordseeheilbad Borkum </span>';
    			} else if (this.facilityid == '139871') {
    				return 'Mellumweg 6b <span class="line2">26434 Nordseeheilbad Schillig </span>';
    			} else if (this.facilityid == '139872') {
    				return 'Am Leuchtfeuer 1 <span class="line2">26434 Nordseeheilbad Schillig </span>';
    			} else if (this.facilityid == '139873') {
    				return 'Mellumweg 8 <span class="line2">26434 Nordseeheilbad Schillig </span>';
    			} else if (this.facilityid == '139853') {
    				return 'Grenzstraße 2-3 <span class="line2">17419 Seebad Ahlbeck </span>';
    			} else if (this.facilityid == '139879') {
    				return 'Obere Strandpromenade 27 <span class="line2">26486 Nordseeheilbad Wangerooge </span>';
    			} else if (this.facilityid == '139906') {
    				return 'Ostseeallee 45 <span class="line2">18225 Ostseebad Kühlungsborn </span>';
    			} else if (this.facilityid == '139915') {
    				return 'Mellumweg 1 <span class="line2">26434 Nordseeheilbad Schillig </span>';
    			} else if (this.facilityid == '139874') {
    				return 'Inselstraße/Mellumweg <span class="line2">26434 Nordseeheilbad Schillig </span>';
    			} else if (this.facilityid == '139867') {
    				return 'Kirchstraße 44 <span class="line2">26757 Nordseeheilbad Borkum </span>';
    			} else if (this.facilityid == '139912') {
    				return 'Ostseeallee 32b <span class="line2">18225 Ostseebad Kühlungsborn </span>';
    			} else if (this.facilityid == '139860') {
    				return 'Bungalowsiedlung 35 <span class="line2">18551 Glowe</span>';
    			} else if (this.facilityid == '139837') {
    				return 'Ostseeallee 35a <span class="line2">18225 Ostseebad Kühlungsborn </span>';
    			} else if (this.facilityid == '139838') {
    				return 'Cubanzestraße 66 <span class="line2">18225 Ostseebad Kühlungsborn </span>';
    			} else if (this.facilityid == '139907') {
    				return 'Zedeliusstraße 47 <span class="line2">26486 Nordseeheilbad Wangerooge </span>';
    			} else if (this.facilityid == '139849') {
    				return 'Dünenweg 1b <span class="line2">23946 Ostseebad Boltenhagen </span>';
    			} else if (this.facilityid == '139918') {
    				return 'Bahnhofstraße 11 <span class="line2">26486 Nordseeheilbad Wangerooge </span>';
    			} else if (this.facilityid == '139883') {
    				return 'Rolf-Dircksen-Weg 27+31 <span class="line2">26739 Nordseebad Wremen </span>';
    			} else if (this.facilityid == '139896') {
    				return 'Gorch-Fock-Straße 1 <span class="line2">26757 Nordseeheilbad Borkum </span>';
    			} else if (this.facilityid == '139839') {
    				return 'Tannenstraße 5b <span class="line2">18225 Ostseebad Kühlungsborn </span>';
    			} else if (this.facilityid == '139854') {
    				return 'Strandpromenade 9-10 <span class="line2">17429 Seebad Bansin </span>';
    			} else if (this.facilityid == '139855') {
    				return 'Maxim-Gorki-Straße 58 <span class="line2">17424 Seebad Heringsdorf </span>';
    			} else if (this.facilityid == '139870') {
    				return 'Hindenburgstraße 40 <span class="line2">26725 Nordseeheilbad Borkum </span>';
    			} else if (this.facilityid == '139844') {
    				return 'Strandparkallee 3-18 <span class="line2">18181 Seeheilbad Graal-Müritz </span>';
    			} else if (this.facilityid == '139898') {
    				return 'Hermannstraße 13 <span class="line2">18225 Ostseebad Kühlungsborn </span>';
    			} else if (this.facilityid == '139886') {
    				return 'Strandstraße 16 <span class="line2">18225 Ostseebad Kühlungsborn </span>';
    			} else if (this.facilityid == '139887') {
    				return 'Strandstraße 32 <span class="line2">18225 Ostseebad Kühlungsborn </span>';
    			} else if (this.facilityid == '139850') {
    				return 'Ahornweg 3-8 <span class="line2">23946 Ostseebad Boltenhagen </span>';
    			} else if (this.facilityid == '139840') {
    				return 'Ulmenstraße 4 <span class="line2">18225 Ostseebad Kühlungsborn </span>';
    			} else if (this.facilityid == '139851') {
    				return 'Urlauberdorf 25-55 <span class="line2">23946 Ostseebad Boltenhagen </span>';
    			} else if (this.facilityid == '139841') {
    				return 'Hafenstraße 12-18 <span class="line2">18225 Ostseebad Kühlungsborn </span>';
    			} else if (this.facilityid == '139856') {
    				return 'Puschkinstraße 18 <span class="line2">17424 Seebad Heringsdorf </span>';
    			} else if (this.facilityid == '139857') {
    				return 'Eichenweg 3 <span class="line2">17424 Seebad Heringsdorf </span>';
    			} else if (this.facilityid == '139920') {
    				return 'Godewind 5a <span class="line2">23570 Ostseeküste Travemünde </span>';
    			} else if (this.facilityid == '139889') {
    				return 'Hermannstraße 18 <span class="line2">18225 Ostseebad Kühlungsborn </span>';
    			} else if (this.facilityid == '139890') {
    				return 'Ostseeallee 27 <span class="line2">18225 Ostseebad Kühlungsborn </span>';
    			} else if (this.facilityid == '139842') {
    				return 'Tannenstraße 7 <span class="line2">18225 Ostseebad Kühlungsborn </span>';
    			} else if (this.facilityid == '139895') {
    				return 'Jasmunder Straße 15 <span class="line2">18609 Ostseebad Binz </span>';
    			} else if (this.facilityid == '139902') {
    				return 'An der Waterkant 9 <span class="line2">18211 Börgerende </span>';
    			} else if (this.facilityid == '139914') {
    				return 'Ostseeallee 48 <span class="line2">23946 Ostseebad Boltenhagen </span>';
    			} else if (this.facilityid == '139891') {
    				return 'Obere Strandpromenade 13 <span class="line2">26486 Nordseeheilbad Wangerooge </span>';
    			} else if (this.facilityid == '160536') {
    				return 'Stockholmer Straße 18 <span class="line2">23966 Hansestadt Wismar </span>';
    			} else if (this.facilityid == '171532') {
    				return 'Stockholmer Straße 20 <span class="line2">23966 Hansestadt Wismar </span>';
    			} else if (this.facilityid == '173864') {
    				return 'Südstrand 300-301 <span class="line2">18609 Ostseebad Binz OT Prora</span>';
    			} else if (this.facilityid == '176135') {
    				return 'Hauptstraße 9 <span class="line2">26465 Nordseeinsel Langeoog</span>';
    			} else if (this.facilityid == '178879') {
    				return 'Ostseeallee 16a <span class="line2">23946 Ostseebad Boltenhagen</span>';
    			} else {
    				return '&nbsp; <span class="line2">&nbsp;</span>';
    			}




    			/*else if (this.regionname === 'Borkum' || this.regionname === 'Bensersiel' || this.regionname === 'Langeoog' || this.regionname === 'Schillig' || this.regionname === 'Wangerooge' || this.regionname === 'Wremen') {
    			    				return 'Nordsee';
    			    			} else if (this.regionname === 'Travemünde' || this.regionname === 'Großenbrode' || this.regionname === 'Boltenhagen' || this.regionname === 'Wismar' || this.regionname === 'Börgerende' || this.regionname === 'Graal-Müritz' || this.regionname === 'Rügen' || this.regionname === 'Usedom') {
    			    				return 'Ostsee';
    			    			} 
    			else {
    				return 'Pending';
    			}*/
    		}
    	}
    };
</script>