export default {
	data: {
		selectedImageIndex: undefined,
		showAnmelden: true
	},
	beforeMount: function () {
		//console.warn("ha", window.location.search);
		var url = new URL(window.location.href);
		var params = {};
		for (let key of url.searchParams.keys()) {
			//console.log("p", key, url.searchParams.get(key));
			params[key] = url.searchParams.get(key);

		}
		this.searchParams = params;
	},
	updated: function () {
		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();
		$(".owl-carousel-facility").owlCarousel({
			items: 1,
			loop: true,
			center: true,
			autoplay: false,
			smartSpeed: 3000,
			autoplayTimeout: 10000,
			dots: false,
			nav: true
		});
	},
	computed: {
		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['info', 'test.de']).join('@');
		}
	}

};