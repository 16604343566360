<template>
    <span>{{getFloorText}}</span>
</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'floor-display',
    	props: {
    		floorval: String
    	},

    	computed: {
    		getFloorText: function() {
    			if (this.floorval) {
    				var floorIndex = parseInt(this.floorval);

    				if (floorIndex == 0) {
    					return 'Tiefparterre';
    				} else if (floorIndex == 1) {
    					return 'Tief - und Hochparterre';
    				} else if (floorIndex == 2) {
    					return 'Erdgeschoss';
    				} else if (floorIndex == 3) {
    					return 'Hochparterre';
    				} else if (floorIndex == 4) {
    					return '1. Obergeschoss';
    				} else if (floorIndex == 5) {
    					return '2. Obergeschoss';
    				} else if (floorIndex == 6) {
    					return '3. Obergeschoss';
    				} else if (floorIndex == 7) {
    					return '4. Obergeschoss';
    				} else if (floorIndex == 8) {
    					return '5. Obergeschoss';
    				} else if (floorIndex == 9) {
    					return '6. Obergeschoss';
    				} else if (floorIndex == 10) {
    					return '7. Obergeschoss';
    				} else if (floorIndex == 11) {
    					return '8. Obergeschoss';
    				} else if (floorIndex == 12) {
    					return '9. Obergeschoss';
    				} else if (floorIndex == 13) {
    					return '10. Obergeschoss';
    				} else if (floorIndex == 14) {
    					return '11. Obergeschoss';
    				} else if (floorIndex == 15) {
    					return '12. Obergeschoss';
    				} else if (floorIndex == 16) {
    					return '13. Obergeschoss';
    				} else if (floorIndex == 17) {
    					return '14. Obergeschoss';
    				} else if (floorIndex == 18) {
    					return '15. Obergeschoss';
    				} else {
    					return ''
    				}

    			} else {
    				return '';
    			}


    		},
    		getPriceText: function() {
    			//console.log('pricelist::' + this.pricelist);
    			var priceText = '';
    			if (this.pricelist) {

    				var i;
    				priceText = "<ul>";
    				var cleaningAdded = false;
    				for (i = 0; i < this.pricelist.length; i++) {

    					if (this.pricelist[i].type == 'FINALCLEANING' && !cleaningAdded) {
    						cleaningAdded = true;
    						priceText += "<li>";
    						priceText += "Endreinigung " + this.pricelist[i].value / 100 + " €";
    						priceText += "</li>";
    					}


    					//console.log('type:' + this.pricelist[i].type);
    				}
    			} else {
    				//console.log('setting price as static text.........');
    				priceText = 'Zusätzliche Kosten können anfallen';
    				/*priceText = "<ul><li>";
    				priceText += "Miete 100 €";
    				priceText += "<li>";
    				priceText += "Endreinigung 25 €";
    				priceText + "</li>";

    				priceText += "</li></ul>";*/
    			}

    			return priceText;
    		}
    	}
    };
</script>