var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.getOfferText.length > 10
    ? _c("div", { staticClass: "offer-box" }, [
        _c(
          "a",
          {
            attrs: {
              href: "javascript:void(0);",
              "data-toggle": "popover",
              title: "Sonderangebote",
              "data-trigger": "click",
              "data-placement": "top",
              "data-html": "true",
              "data-content": _vm.getOfferText
            }
          },
          [
            _c("span", { staticClass: "offer-left" }, [
              _vm._v("Sonderangebote verfügbar")
            ])
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }